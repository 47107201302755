import React from "react";

export default function createCtxUseState<A>(defaultValue: A) {

    type UpdateType = React.Dispatch<React.SetStateAction<typeof defaultValue>>

    const defaultUpdate: UpdateType = () => defaultValue
    
    const ctx = React.createContext({ state: defaultValue, setState: defaultUpdate })

    function Provider(props: React.PropsWithChildren<{}>) {
      const [state ,setState] = React.useState(defaultValue)

      return <ctx.Provider value={{ state, setState }} {...props} />
    }

    return [ctx, Provider] as const

  }



  // usage
  const [ctx, TextProvider] = createCtxUseState("someText")

  export const TextContext = ctx

  export function App() {
    return (
      <TextProvider>
        <Component />
      </TextProvider>
    )
  }
  export function Component() {
    const { state, setState } = React.useContext(ctx)
    return (
      <label>
        {state}
        <input type="text" onChange={e => setState(e.target.value)} />
      </label>
    )
  }