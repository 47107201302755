import React, { useState, useContext } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabButtons from "./tabButton";
import { ctxTab } from "../../pageManager/pageProvider";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    tab: {
      color: "#000",
    },
    tabNight: {
      color: "#000",
    }
  })
);

function MenuTab(props: any) {
  const { state, setState } = React.useContext(ctxTab);

  const colorTab = props.modNight;

  const classes = useStyles();

  function handleClick(index: string) {
    setState(index);
    console.log("statooooo", index);
  }

  return (
    <>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={state}
        //onChange={handleChange}
        indicatorColor={colorTab ? "secondary" : "primary"}
        textColor={colorTab ? "primary" : "secondary"}
        aria-label="icon tabs example"
        centered
      >
        {TabButtons.map((index, id) => (
          <Tab
            key={index.title}
            value={index.tag}
            icon={index.Icon}
            className={!colorTab ? classes.tab : classes.tabNight}
            //label={index.title}
            //disabled={state === index.tag}
            selected={state === index.tag}
            onClick={() => handleClick(index.tag)}
          />
        ))}
      </Tabs>
    </>
  );
}

export default MenuTab;
