import React from "react";

// create context with no upfront defaultValue
// without having to do undefined check all the time

export default function createCtxAPI<A>() {
    const ctx = React.createContext<A | undefined>(undefined)
    function useCtx() {
      const c = React.useContext(ctx)
      if (!c) throw new Error("useCtx must be inside a Provider with a value")
      return c
    }
    return [useCtx, ctx.Provider] as const
  }
  // usage - no need to specify value upfront!
  export const [useCtx, SettingProvider] = createCtxAPI<string>()
  export function App() {
    // get a value from a hook, must be in a component
    const key = useLocalStorage('key')
    return (
      <SettingProvider value={key}>
        <Component />
      </SettingProvider>
    )
  }
  export function Component() {
    const key = useCtx() // can still use without null check!
    return <div>{key}</div>
  }
  
  
  function useLocalStorage(a: string) {
    return 'secretKey' + a
  }