import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Header, Body } from "./layout";
import { PageProvider } from "./pageManager/pageProvider";
import { ModNight } from "./pageManager/nightProvider";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#fafafa" },
    secondary: { main: "#fbbc05" },
    action: { selected: "#FFD400 ", disabled: "#FFD000" }
  }
});

const themeNight = createMuiTheme({
  palette: {
    primary: { main: "#fafafa" },
    secondary: { main: "#757474" },
    action: { selected: "#FFD400 ", disabled: "#FFD000" }
  }
});

const App: React.FC = () => {
  const { state } = React.useContext(ModNight);

  return (
    <>
      <ThemeProvider theme={state ? themeNight : theme}>
        <PageProvider>
          <div className="App">
            <Header />
            <Body />
          </div>
        </PageProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
