import React from "react";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import CodeIcon from "@material-ui/icons/Code";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";

const TabButtons = [
  { id: 1, tag: "I", title: "home", Icon: <InsertEmoticonIcon /> },
  { id: 2, tag: "II", title: "resume", Icon: <DonutLargeIcon /> },
  { id: 3, tag: "III", title: "activity", Icon: <CodeIcon /> },
  { id: 4, tag: "IV", title: "cafe", Icon: <LocalCafeIcon /> },

];

export default TabButtons;
