import React from "react";
import IconButton from "@material-ui/core/IconButton";
import GitHubIcon from "@material-ui/icons/GitHub";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import { ModNight } from "../pageManager/nightProvider";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import mb from "../mb.jpg";

import "./header.css";

function Header() {
  const { state, setState } = React.useContext(ModNight);

  function HandleClick() {
    setState(state => !state);
    console.log(state);
  }

  return (
    <div className={state ? "headerNight" : "headerLight"}>
      <img
        src={mb}
        className={!state ? "header-logo" : "header-logo-night"}
        alt="logo"
      />

      <h5>{"Hi Everyone!"}</h5>

      <h2>{"My name is Morteza"}</h2>

      <h5>{"welcome to my official website"}</h5>

      <div className="header-icon">
        <IconButton href="https://github.com/Baziar" target="_blank">
          <GitHubIcon color={!state ? "primary" : "secondary"} />
        </IconButton>

        <IconButton href="https://twitter.com/Baziar_Dev" target="_blank">
          <TwitterIcon color={!state ? "primary" : "secondary"} />
        </IconButton>

        <IconButton href=" " target="_blank">
          <FacebookIcon color={!state ? "primary" : "secondary"} />
        </IconButton>

        <IconButton href="https://www.linkedin.com/in/baziar/" target="_blank">
          <LinkedInIcon color={!state ? "primary" : "secondary"} />
        </IconButton>

        <IconButton href="mailto:Mail@Baziar.Dev" target="_blank">
          <EmailIcon color={!state ? "primary" : "secondary"} />
        </IconButton>
      </div>
      <div className="header-moon">
        <p>{"V 1.0.0"}</p>
        {!state ? (
          <div>
            <IconButton>
              <Brightness2Icon onClick={HandleClick} color="primary" />
            </IconButton>
          </div>
        ) : (
          <div>
            <IconButton>
              <WbSunnyIcon onClick={HandleClick} color="secondary" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
