import React from "react";
import { ctxTab } from "./pageProvider";
import mb from "../mb.jpg";

const Home = React.lazy(() =>
  import("./pages/index").then(({ Home }) => ({
    default: Home
  }))
);

const Smile = React.lazy(() =>
  import("./pages/index").then(({ Smile }) => ({
    default: Smile
  }))
);

const Edu = React.lazy(() =>
  import("./pages/index").then(({ Edu }) => ({
    default: Edu
  }))
);

const Activity = React.lazy(() => 
import("./pages/index").then(({Activity}) => ({
  default: Activity
}))
)

const Coffee = React.lazy(() => 
import("./pages/index").then(({Coffee}) => ({
  default: Coffee
}))
)

function PageManager() {
  const { state } = React.useContext(ctxTab);

  switch (state) {
    case "I":
      return (
        <React.Suspense
          fallback={
            <div>
              <img src={mb} alt="spin" />
            </div>
          }
        >
          <Smile />
        </React.Suspense>
      );
    case "II":
      return (
        <React.Suspense
          fallback={
            <div>
              <img src={mb} alt="spin" />{" "}
            </div>
          }
        >
          <Edu />
        </React.Suspense>
      );
    case "III":
      return (
        <React.Suspense
          fallback={
            <div>
              <img src={mb} alt="spin" />{" "}
            </div>
          }
        >
          <Activity />
        </React.Suspense>
      );
    case "IV":
      return (
        <React.Suspense
          fallback={
            <div>
              <img src={mb} alt="spin" />
            </div>
          }
        >
          <Coffee/>
        </React.Suspense>
      );

    default:
      return (
        <>
          <React.Suspense fallback={<img src={mb} alt="spin" />}>
            <Home />
          </React.Suspense>
        </>
      );
  }
}

export default PageManager;
