import React, { useState } from "react";
import MenuTab from "../components/menu/menuTab";
import PageManager from "../pageManager/pageManager";
import { ModNight } from "../pageManager/nightProvider";

import "./body.css";

const Body = () => {
  const { state } = React.useContext(ModNight);

  return (
    <>
      <div className={state ? "body-night" : "body"}>
        <div className={state ? "body-tab-night" : "body-tab"}>
          <MenuTab modNight={state} />
        </div>

        <div >
          <PageManager />
        </div>
      </div>
    </>
  );
};

export default Body;
